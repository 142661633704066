import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewName, VIEW_NAMES } from '../../../views/manifest'

export const selectViews = (state: any): Record<ViewName, ViewState> => state.views.views;

export const selectActions = (state: any): ActionsState => state.views.actions;

interface WasJustUpdatedPayload {
  viewName: ViewName
}

const WasJustUpdatedReducer = {
    reducer: (state: any, action: PayloadAction<WasJustUpdatedPayload>) => {
       return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    lastUpdated: new Date()
                }
            }
        }
    },
    prepare: (viewName: ViewName) => ({
      payload: {
        viewName: viewName
      }
    })
}

interface UpdateViewExistsPayload {
  viewName: ViewName
  exists: boolean | "Unknown"
}

const UpdateViewExistsReducer = {
    reducer: (state: any, action: PayloadAction<UpdateViewExistsPayload>) => {
        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    exists: action.payload.exists
                }
            }
        }
    },
    prepare: (viewName: ViewName, exists: boolean | "Unknown") => ({
        payload: {
            viewName: viewName, exists: exists
        }
    })
}

interface UpdateRowscnPayload {
    viewName: ViewName
    rowscn: number | "Unknown"
}

const UpdateRowscnReducer = {
    reducer: (state: any, action: PayloadAction<UpdateRowscnPayload>) => {

        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    rowscn: action.payload.rowscn
                }
            }
        }
    },
    prepare: (viewName: ViewName, rowscn: number | "Unknown") => ({
        payload: {
            viewName: viewName, rowscn: rowscn
        }
    })
}

interface UpdateRowCountPayload {
  viewName: ViewName,
  rowCount: number | "Unknown"
}

const UpdateRowCountReducer = {
    reducer: (state: any, action: PayloadAction<UpdateRowCountPayload>) => {
      return {
        ...state,
        views: {
          ...state.views,
          [action.payload.viewName]: {
            ...state.views[action.payload.viewName],
            rowsCount: action.payload.rowCount
          }
        }
      }
    },
    prepare: (viewName: ViewName, rowCount: number | "Unknown") => ({
      payload: {
        viewName: viewName, rowCount: rowCount
      }
    })
}


interface ActionIsStartedPayload {
    action: string
}

const ActionIsStartedReducer = {
    reducer: (state: any, action: PayloadAction<ActionIsStartedPayload>) => {
        let startedActions = [...state.actions.actionsInProgress]
        startedActions.push(action.payload.action)

        return {
            ...state,
            actions: {
                ...state.actions,
                actionsInProgress: startedActions
            }
        }
    },
    prepare: (action: string) => ({
        payload: {
            action: action
        }
    })
}

interface ActionIsCompletedPayload {
    action: string
}

const ActionIsCompletedReducer = {
    reducer: (state: any, action: PayloadAction<ActionIsCompletedPayload>) => {
        let startedActions = [...state.actions.actionsInProgress]
        let completedActions = [...state.actions.actionsSucceded]

        startedActions = startedActions.filter(s => s != action.payload.action)
        completedActions.push(action.payload.action)

        return {
            ...state,
            actions: {
                ...state.actions,
                actionsInProgress: startedActions,
                actionsSucceded: completedActions
            }
        }
    },
    prepare: (action: string) => ({
        payload: {
            action: action
        }
    })
}

interface ActionIsFailedPayload {
    action: string
    err: string
}

const ActionIsFailedReducer = {
    reducer: (state: any, action: PayloadAction<ActionIsFailedPayload>) => {
        let startedActions = [...state.actions.actionsInProgress]
        let failedActions = [...state.actions.actionsFailed]

        startedActions = startedActions.filter(s => s != action.payload.action)
        failedActions.push(`${action.payload.action} : ${action.payload.err}`)

        return {
            ...state,
            actions: {
                ...state.actions,
                actionsInProgress: startedActions,
                actionsFailed: failedActions
            }
        }
    },
    prepare: (action: string, err: string) => ({
        payload: {
            action: action, err: err
        }
    })
}

interface ClearActionsPayload {
}

const ClearActionsReducer = {
    reducer: (state: any, action: PayloadAction<ClearActionsPayload>) => {
        return {
            ...state,
            actions: {
                actionsInProgress: [],
                actionsSucceded: [],
                actionsFailed: []
            }
        }
    },
    prepare: () => ({
        payload: {}
    })
}

interface SetUpdateSchedulePayload {
    viewName: ViewName,
    schedule: {
        seconds: number,
        minutes: number,
        hours: number,
        days: number
    }
}

const SetUpdateScheduleReducer = {
    reducer: (state: any, action: PayloadAction<SetUpdateSchedulePayload>) => {
        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    schedule: action.payload.schedule
                }
            }
        }
    },
    prepare: (viewName: ViewName, schedule: {
        seconds: number,
        minutes: number,
        hours: number,
        days: number
    }) => ({
        payload: {
            viewName: viewName, schedule: schedule
        }
    })
}

export interface ViewState {
    exists: boolean | "Unknown"
    rowscn: number | "Unknown"
    rowsCount: number | "Unknown"
    lastUpdated: Date | "Never"
    schedule: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
    }
}

function defaultViewState(): ViewState {
  return {
    exists: "Unknown",
    rowscn: "Unknown",
    rowsCount: "Unknown",
    lastUpdated: "Never",
    schedule: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
    }
  }
}

export interface ActionsState {
    actionsInProgress: string[]
    actionsSucceded: string[]
    actionsFailed: string[]
}

export interface ViewSliceState {
    actions: ActionsState
    views: Record<ViewName, ViewState>
}

const initialState: ViewSliceState = {
    actions: {
        actionsInProgress: [],
        actionsSucceded: [],
        actionsFailed: [],
    },
    views: {
        "inventory": defaultViewState(),
        "test_view": defaultViewState(),
        "jobs": defaultViewState(),
        "services": defaultViewState(),
        "costcols": defaultViewState(),
        "size": defaultViewState(),
        "styles": defaultViewState(),
        "orders": defaultViewState(),
        "retail_staddr": defaultViewState(),
        "rpt_bssales": defaultViewState(),
        "invoices": defaultViewState(),
        "line_items": defaultViewState(),
        "invoice_line_items": defaultViewState(),
        "customers": defaultViewState(),
        "stores": defaultViewState(),
        "order_track_log": defaultViewState(),
        "credit_memo_header": defaultViewState(),
        "credit_memo_line_items": defaultViewState(),
        "purchase_orders": defaultViewState(),
        "colors": defaultViewState(),
        "cust_skus": defaultViewState(),
        "pick_ticket_headers": defaultViewState(),
        'master_styles': defaultViewState(),
        'art_color': defaultViewState(),
        'color_by_art': defaultViewState(),
        'art': defaultViewState(),
        'customer_remarks': defaultViewState(),
        'customer_credit_remarks': defaultViewState(),
        'order_headers': defaultViewState()
    },
}

export const viewsSlice = createSlice({
    name: 'views',
    initialState,
    reducers: {
        updateViewExists: UpdateViewExistsReducer,
        updateRowscn: UpdateRowscnReducer,
        updateRowCount: UpdateRowCountReducer,
        actionIsStarted: ActionIsStartedReducer,
        actionIsCompleted: ActionIsCompletedReducer,
        actionIsFailed: ActionIsFailedReducer,
        clearActions: ClearActionsReducer,
        wasJustUpdated: WasJustUpdatedReducer,
        setUpdateSchedule: SetUpdateScheduleReducer,
    }
})

export const {updateViewExists, updateRowscn, updateRowCount, actionIsStarted, actionIsCompleted, actionIsFailed, clearActions, wasJustUpdated, setUpdateSchedule} = viewsSlice.actions
